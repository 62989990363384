<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      rounded="sm"
      spinner-variant="primary"
    >
      <b-card
        class="mt-2"
      >
        <b-card-title class="lead">
          Manage Mentors
        </b-card-title>
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search"
              type="text"
            />
            <b-button
              variant="primary"
              @click="$bvModal.show('addjurymodal')"
            >
              <feather-icon
                class="mr-25"
                icon="PlusIcon"
              />
              Add Mentor
            </b-button>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength,
            position: 'top',
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.label === 'Name'">
              {{ props.row.users_customuser.full_name }}
              <b-link
                v-b-tooltip:hover="'View Details'"
                class="ml-50"
                size="sm"
                variant="flat-primary"
                @click="$showEntityModal(props.row.id)"
              >
                <feather-icon icon="InfoIcon" />
              </b-link>
            </span>
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                v-if="props.row.users_customuser.programs_partnerstables.length>0"
                :variant="props.row.users_customuser.programs_partnerstables[0].is_active || props.row.status.toLowerCase() !== 'accept' ?statusVariant(props.row.status.toLowerCase()):'light-danger'"
                class="text-capitalize mr-1"
              >
                {{ props.row.status.toLowerCase()==="accept"?props.row.users_customuser.programs_partnerstables[0].is_active?"Active":"Inactive":props.row.status }}</b-badge>
              <b-badge
                v-else
                :variant="props.row.status?statusVariant(props.row.status):'light-primary'"
              >
                {{ props.row.status?props.row.status:"Invited" }}
              </b-badge>

              <b-form-checkbox
                v-if="props.row.status.toLowerCase() === 'accept' && props.row.users_customuser.programs_partnerstables.length>0"
                v-b-tooltip="props.row.users_customuser.programs_partnerstables[0].is_active ? 'Deactivate' : 'Activate'"
                :checked="props.row.users_customuser.programs_partnerstables[0].is_active"
                class="mt-50 custom-control-primary"
                name="check-button"
                switch
                @change="updateStatus(props.row.users_customuser.programs_partnerstables[0].is_active, props.row.users_customuser.programs_partnerstables[0].id, 'is_active')"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </span>

            <span v-else-if="props.formattedRow[props.column.field].field === 'action'">
              <b-button
                v-if="props.formattedRow[props.column.field].users_customuser.programs_partnerstables.length>0"
                size="sm"
                variant="outline-primary"
                class="text-capitalize mr-1"
                @click="selectedRow = props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].id; $bvModal.show('assignStartup'); getStudents()"
              >Assign Student</b-button>
              <b-button
                size="sm"
                variant="flat-danger"
                class="mr-50"
                aria-label="Delete"
                @click="$bvModal.show('deleteModal'); selectedfid = props.formattedRow[props.column.field].id; selectedRow = props.formattedRow[props.column.field].users_customuser.programs_partnerstables?props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].id:null;"
              ><feather-icon icon="TrashIcon" /></b-button>
            </span>

            <span v-else-if="props.formattedRow[props.column.field].field === 'jury'">
              <b-badge
                v-if="props.formattedRow[props.column.field].users_customuser.programs_partnerstables.length>0"
                :variant="props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_jury?'light-primary':'light-danger'"
                style="text-transform: capitalize;"
              >
                {{ props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_jury?"Is Jury":"Not Jury" }}
              </b-badge><br>
              <b-form-checkbox
                v-if="props.formattedRow[props.column.field].users_customuser.programs_partnerstables.length>0"
                v-b-tooltip="props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_jury ? 'Disable Jury' : 'Make Jury'"
                :checked="props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_jury"
                class="mt-50 custom-control-primary"
                name="check-button"
                switch
                @change="updateStatus(props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_jury, props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].id, 'is_jury')"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </span>

            <span v-else-if="props.formattedRow[props.column.field].field === 'supermentor'">
              <b-badge
                v-if="props.formattedRow[props.column.field].users_customuser.programs_partnerstables.length>0"
                :variant="props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_super?'light-primary':'light-danger'"
                style="text-transform: capitalize;"
              >
                {{ props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_super?"Is Super Mentor":"Not Super Mentor" }}
              </b-badge><br>
              <b-form-checkbox
                v-if="props.formattedRow[props.column.field].users_customuser.programs_partnerstables.length>0"
                v-b-tooltip="props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_super ? '' : ''"
                :checked="props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_super"
                class="mt-50 custom-control-primary"
                name="check-button"
                switch
                @change="updateStatus(props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].is_super, props.formattedRow[props.column.field].users_customuser.programs_partnerstables[0].id, 'is_super')"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-top"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mb-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','20','30']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                  variant="light-primary"
                >{{
                  props.total
                }} entries
                </b-badge></span></span>

              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-50"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
    <b-modal
      id="deleteModal"
      ok-title="Yes, Delete"
      ok-variant="danger"
      button-size="sm"
      no-close-on-backdrop
      centered
      size="sm"
      title="Confirm Delete"
      @ok="deleteMentor()"
    >
      <h6>Are you sure you want to delete this mentor?</h6>
    </b-modal>
    <b-modal
      id="assignStartup"
      ok-only
      ok-title="Assign"
      :title="`Assign Student to ${selectedRow ? rows.find(e => e.users_customuser.programs_partnerstables[0].id === selectedRow).users_customuser.full_name : ''}`"
      no-close-on-backdrop
      @ok="assignStartup"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Select Students"
            label-for="selectStudents"
          >
            <v-select
              id="selectStudents"
              v-model="startupParticipant"
              :get-option-label="option => option.programs_applicantstable ? option.programs_applicantstable.users_customuser.full_name : null"
              :options="studentsList"
              :reduce="option => option.id"
              placeholder="Select Students"
              multiple="true"
              class="mb-1"
            />

            <vue-good-table
              :columns="columns2"
              :pagination-options="{
                enabled: true,
                perPage:pageLength,
                position: 'top',
              }"
              :rows="assignedStudents"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.formattedRow[props.column.field].field === 'action'">
                  <b-button
                    variant="danger"
                    size="sm"
                  >Deactivate
                  </b-button>
                </span>

                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-top"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mb-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10','20','30']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                      variant="light-primary"
                    >{{
                      props.total
                    }} entries
                    </b-badge></span></span>

                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      align="right"
                      class="mt-50"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="addjurymodal"
      size="lg"
      title="Add Mentor"
      hide-footer
      no-close-on-backdrop
      @close="sendInvitation= false; mentor.email = ''; mentor.designation = ''; searchData = null; mentor.id = null;"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Email"
            label-for="mentor"
          >
            <b-overlay
              :show="mutationLoading"
            >
              <div class="bg-light rounded-sm">
                <b-form-input
                  id="mentor"
                  v-model="mentor.email"
                  lazy
                  placeholder="Enter Email"
                  type="email"
                  @blur="fetchUser()"
                />
                <div class="d-flex align-items-center">
                  <p
                    v-show="mentor.email && !mutationLoading"
                    class="px-1 py-50"
                  >Result:
                    <b-badge
                      v-if="searchData === false"
                      pill
                      variant="danger"
                    >No User Found!
                    </b-badge>
                    <b-badge
                      v-else
                      class="text-capitalize"
                      pill
                      variant="primary"
                    >{{ searchData }}
                    </b-badge>
                    <b-badge
                      v-if="mentor.id && existingUser"
                      class="ml-25"
                      pill
                      variant="warning"
                    >
                      User already exists!
                    </b-badge>
                  </p>
                  <b-button
                    v-if="searchData === false && !sendInvitation"
                    size="sm"
                    variant="outline-primary"
                    @click="sendInvitation = true;"
                  >Invite
                  </b-button>
                </div>
              </div>
            </b-overlay>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Designation"
            label-for="mentorDesignation"
          >
            <b-form-input
              id="mentorDesignation"
              v-model="mentor.designation"
              placeholder="Designation of mentor"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-button
            :disabled="!mentor.email || existingUser || (!mentor.id && !sendInvitation)"
            class="mt-2"
            variant="outline-primary"
            @click="inviteOrAddMentor()"
          >
            <feather-icon
              class="mr-25"
              icon="PlusIcon"
            />
            Add
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BBadge,
  BButton,
  BCard,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BFormInput,
    BCard,
    BCardTitle,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BRow,
    // AppCollapse,
    // AppCollapseItem,
    BBadge,
    VueGoodTable,
    vSelect,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      mutationLoading: false,
      pageLength: 10,
      startupParticipant: null,
      options: [],
      mentor: {
        id: null,
        email: null,
        designation: null,
      },
      searchData: null,
      sendInvitation: false,
      selectedfid: null,
      searchTerm: '',
      selectedRow: null,
      rows: [],
      studentsList: [],
      assignedStudents: [],
      columns: [

        {
          label: 'Name',
          field(row) {
            if (!row.users_customuser) return '-'
            return row.users_customuser.full_name || '-'
          },
        },
        {
          label: 'Designation',
          field: 'role',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: '-- No Filter --',
            filterDropdownItems: [
              {
                value: 'accept',
                text: 'Accept',
              }, {
                value: 'invited',
                text: 'Invited',
              }, {
                value: 'reject',
                text: 'Reject',
              }],
          },
        },
        {
          label: 'Jury',
          field(row) {
            if (!row.users_customuser) return ''
            return ({
              field: 'jury',
              status: row.status,
              users_customuser: row.users_customuser,
            })
          },
        },
        {
          label: 'Super Mentor',
          field(row) {
            if (!row.users_customuser) return ''
            return ({
              field: 'supermentor',
              status: row.status,
              users_customuser: row.users_customuser,
            })
          },
        },
        {
          label: 'Action',
          field(row) {
            if (!row.users_customuser) return ''
            return ({
              field: 'action',
              status: row.status,
              id: row.id,
              users_customuser: row.users_customuser,
            })
          },
        },
      ],
      columns2: [
        {
          label: 'Name',
          field(row) {
            return row.programs_startupparticipant.programs_applicantstable.users_customuser.full_name || '-'
          },
        },
        {
          label: 'Action',
          field(row) {
            return ({
              field: 'action',
              id: row.id,
              programs_startupparticipant: row.programs_startupparticipant,
            })
          },
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        accept: 'light-success',
        invited: 'light-primary',
        reject: 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    existingUser() {
      return this.rows.findIndex(e => e.user_id === this.mentor.id) !== -1
    },
  },
  methods: {
    inviteOrAddMentor() {
      if (this.searchData === false && this.sendInvitation) {
        this.inviteMentor()
      } else {
        this.addMentor()
      }
    },
    fetchUser() {
      if (!this.mentor.email) return false
      this.mutationLoading = true
      this.$apollo.query({
        query: gql`query options($q: String!){
              users_customuser(where: {email: {_ilike: $q}}) {
                      full_name
                      email
                      id
                    }
              }`,
        variables: { q: this.mentor.email },
      })
        .then(({ data }) => {
          this.mutationLoading = false
          this.searchData = !data.users_customuser[0] ? false : data.users_customuser[0].full_name
          this.mentor.id = data.users_customuser[0]?.id || null
        })
      return true
    },
    getStudents() {
      this.$apollo.queries.studentsList.refetch()
      this.$apollo.queries.assignedStudents.refetch()
    },
    assignStartup() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_startupassignmenttable(objects: [${this.startupParticipant.map(e => `{participant_id: "${e}", partner_id: "${this.selectedRow}"}`)}]) {
            affected_rows
          }
        }`,
        update: (store, { data: { insert_programs_startupassignmenttable } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_startupassignmenttable.affected_rows ? 'Added successfully' : 'Failed to add',
              icon: insert_programs_startupassignmenttable.affected_rows ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_startupassignmenttable.affected_rows ? 'success' : 'warning',
            },
          })
          this.getStudents()
          this.startupParticipant = []
          this.mutationLoading = false
        },
      })
    },
    inviteMentor() {
      this.mutationLoading = true
      const {
        email,
        designation,
      } = this.mentor
      const programId = this.$route.params.id

      this.$apollo.mutate({
        mutation: gql`
        mutation inviteMentor($email: String!, $role: String!, $status: String!, $program_id: Int!) {
          insert_users_associationtable_one(object: {users_customuser: {data: {email: $email}}, role: $role, status: $status, program_id: $program_id}) {
            role
            status
            users_customuser {
              email
            }
            id
          }
        }`,
        variables: {
          email,
          role: designation,
          status: 'Invited',
          program_id: parseInt(programId, 10),
        },
        update: () => {
          this.mentor.id = null
          this.mentor.designation = null
          this.$apollo.queries.rows.refetch()
          this.$bvModal.hide('addjurymodal')
          this.mutationLoading = false
        },
      })
    },
    addMentor() {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
            insert_users_associationtable_one(object: {program_id: ${this.$route.params.id}, role: "${this.mentor.designation}", status: "Invited", user_id: ${this.mentor.id}}) {
              id
            }
          }`,
        update: () => {
          this.mentor.id = null
          this.mentor.designation = null
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateStatus(status, item, col) {
      const update = status ? 'false' : 'true'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_partnerstable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    deleteMentor() {
      const item = this.selectedRow
      const association = this.selectedfid
      this.mutationLoading = true
      if (item) {
        this.$apollo.mutate({
          mutation: gql`mutation {
            update_programs_partnerstable_by_pk(pk_columns: {id: ${item}}, _set: {is_deleted: true}) {
              id
            }
          }`,
          update: () => {
            this.mutationLoading = false
          },
        })
      }
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_users_associationtable_by_pk(pk_columns: {id: ${association}}, _set: {is_deleted: true}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          users_associationtable(where: {program_id: {_eq: ${this.$route.params.id}}}) {
            role
            status
            users_customuser {
              email
              full_name
              programs_partnerstables(where: {program_id: {_eq: ${this.$route.params.id}}}) {
                is_active
                is_jury
                is_super
                id
              }
            }
            id
            user_id
          }
        }`
      },
      update: data => {
        // Update: if users_associationtable.users_customuser.programs_partnerstables length is 0, then it is not accepted, and should be shown in last
        const accepted = data.users_associationtable.filter(e => e.users_customuser.programs_partnerstables.length > 0)
        const notAccepted = data.users_associationtable.filter(e => e.users_customuser.programs_partnerstables.length === 0)
        return [...accepted, ...notAccepted]
      },
    },
    studentsList: {
      query() {
        return gql`{
          programs_startupparticipants(where: {programs_applicantstable: {program_id: {_eq: ${this.$route.params.id}}}, is_active: {_eq: true}, programs_startupassignmenttables_aggregate: {count: {predicate: {_eq: 0}, filter: {partner_id: {_eq: ${this.selectedRow}}}}}}) {
            id
            programs_applicantstable {
              users_customuser {
                full_name
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants,
    },
    assignedStudents: {
      query() {
        return gql`{
          programs_startupassignmenttable(where: {partner_id: {_eq: ${this.selectedRow}}}) {
            participant_id
            partner_id
            programs_startupparticipant {
              programs_applicantstable {
                users_customuser {
                  full_name
                }
              }
            }
          }
        }`
      },
      update: data => data.programs_startupassignmenttable,
    },
  },
}
</script>
